import React, {useState} from "react";
import { connect } from "react-redux";
import CardBottom from "./card-bottom";
import {FaChevronUp, FaChevronDown, FaPlus} from "react-icons/fa";

const ControlsBottom = props => {

    const {data, callback} = props
    const [expanded, setExpanded] = useState(true)

    const filteredData = data.slice(0, 10)

    return (
        <div className='cardsContainer'>
            <div className='d-flex-ac'>
                <div className='ml-10 whiteBox d-flex-ac enabledClick' style={{width: 'fit-content'}} onClick={() => setExpanded(!expanded)}>
                    {expanded ? <FaChevronDown size={10} /> : <FaChevronUp size={10} />}
                    <p className='ml-5 fs-12 fcblack'>Свежие активности</p>
                </div>
                <div className='ml-10 whiteBox d-flex-ac enabledClick' style={{width: 'fit-content'}}>
                    <FaPlus size={10} />
                    <p className='ml-5 fs-12'><a className='fcblack' href='https://t.me/moydvor_bybot' target='_blank' rel='noopener noreferrer'>Добавить</a></p>
                </div>
            </div>
            {expanded &&
                <div className='d-flex mt-10 cardsContainer-box'>
                    {filteredData.map((item, index) => <CardBottom key={index} item={item} callback={(item) => callback(item)}/>)}
                </div>
            }
        </div>
    );
}

const mapStateToProps = ({ data: { data }}) => {
    return { data };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //updateMapVisibleArea: (payload) => dispatch(updateMapVisibleArea(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlsBottom);

