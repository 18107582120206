import React from "react";
import {Route, Switch} from "react-router-dom";

import "./main.css";
import "./shortcuts.css";
import "./sliding-pane.css"
import ErrorPanel from "../error-panel/error-panel";
import Map from "../map/map";
import MediaView from "../media-view/media-view";
import ControlsHeader from "../controls-header/controls-header";

const App = () =>  {

    return (
        <div className="wrapper">
            <div className="container">
                <Switch>
                    <Route path='/' exact>
                        <Map />
                        <ControlsHeader />
                    </Route>
                    <Route path='/:id' exact component={MediaView} />

                </Switch>
            </div>
            <ErrorPanel />
        </div>
    );
}

export default App;
