import React, {useState} from "react";
import { connect } from "react-redux";
import logo from '../../assets/moydvor_logo.svg'
import {DAY, DAY3, WEEK, MONTH, MONTH3, MONTH6} from "../../constants/durations";
import {fetchData} from "../../actions";

const ControlsHeader = props => {

    const [time, setTime] = useState(MONTH)

    const {fetchData} = props

    const updateTime = (value) => {
        setTime(value);
        fetchData(value)
    }

    return (
        <div className='controlsHeaderContainer'>
            <div className='whiteBox'>
                <img width={60} src={logo} alt='logo1' />
            </div>
            <div>
                <select value={time} onChange={(e) => updateTime(e.target.value)}>
                    <option value={DAY}>За день</option>
                    <option value={DAY3}>За 3 дня</option>
                    <option value={WEEK}>За неделю</option>
                    <option value={MONTH}>За месяц</option>
                    <option value={MONTH3}>За 3 месяца</option>
                    <option value={MONTH6}>За 6 месяцев</option>
                </select>
            </div>
        </div>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: fetchData(dispatch)
    };
};

export default connect( null, mapDispatchToProps)(ControlsHeader);

