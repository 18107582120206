import React from "react";
import {FiFileText} from "react-icons/fi";
import {getTextDirection} from "../../utils/utils";

const CardBottom = props => {

    const {item, callback} = props

    const descriptiontext = item.title.substring(0, 60) + (item.title.length > 60 ? '...' : '');

    return (
        <div className='whiteBox mr-10 enabledClick' onClick={() => callback(item)} style={{width: 162}}>
            {item.type !== 'text' &&
            <div className='card-image-container'>
                <img src={item.thumb} alt='card'/>
            </div>
            }
            {(item.type === 'text' || item.type === 'link') &&
            <div className='card-image-container'>
                <div className='d-flex-ac p-5'>
                    <FiFileText size={14} color='#aaaaaa' />
                    <p className='fs-8 fcblack ml-5'>{getTextDirection(item.direction || 'protest')}</p>
                </div>
                <p className='fs-10 mt-10'>{descriptiontext}</p>
            </div>
            }
            <div className='mt-10 w-150 d-flex-center'>
                <p className='fs-8'>{`${item.area} / ${item.region}`}</p>
            </div>
        </div>
    );
}

export default CardBottom

