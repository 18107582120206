import React, {useEffect, useState} from "react";
import FormMap from "./form-map";
import { connect } from "react-redux";
import ControlsBottom from "../cards-bottom/controls-bottom";
import SlidingPane from "react-sliding-pane";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Activities from "../activities/activities";
import PopupPlayer from "../popup-player";
import {fetchData} from "../../actions";

const Map = props => {

    const {data, dataLoading, fetchData} = props

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { width } = useWindowDimensions();

    const [panelOpen, setPanelOpen] = useState(false);
    const [playerOpen, setPlayerOpen] = useState(false);

    const [panelItem, setPanelItem] = useState(null);
    const [imageItem, setImageItem] = useState(null);

    const [mediaList, setMediaList] = useState(null);

    if (dataLoading) return (
        <p className='d-flex-center'>Загружаем данные...</p>
    )

    const openPanel = (item) => {
        const area = `${item.area} / ${item.region}`
        const filteredData = data.filter(activity => activity.region === item.region);

        setPanelItem({area, data: filteredData});
        setPanelOpen(true)
    }

    const openMedia = (item, latest= false) => {
        if (latest) {
            setMediaList(data.slice(0, 10));
        } else {
            setMediaList(data.filter(media => media.region === item.region))
        }
        setImageItem(item);
        setPlayerOpen(true)
    }

    const updateFromMap = ({ bounds }) => {

        //props.updateMapVisibleArea({ bounds, zoom, center });
    }

    return (
        <div className="map">
            <FormMap
                data={data}
                initialZoom={props.zoom}
                callbackBoundaries={updateFromMap}
                callback={openPanel}
            />
            <ControlsBottom callback={(item) => openMedia(item, true)}/>

            <SlidingPane
                isOpen={panelOpen}
                title="Дворовые активности"
                width={width < 450 ? '100%' : '50%'}
                subtitle=""
                onRequestClose={() => {
                    setPanelOpen(false);
                }}>
                <div>
                    {panelItem && <Activities items={panelItem} callback={(item) => openMedia(item)} />}
                </div>
            </SlidingPane>

            {playerOpen && imageItem &&
                <PopupPlayer mediaList={mediaList} item={imageItem} callbackClose={() => setPlayerOpen(false)}  />
            }

        </div>
    );

}


const mapStateToProps = ({ data: { data, dataLoading }}) => {
    return { data, dataLoading };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: fetchData(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

