
const SERVER_URL = process.env.REACT_APP_API_URL

const CONNECTION_ERROR_MESSAGE = 'Произошла ошибка при запросе данных';

export default class APIService {

    _data = [
        {
            _id: 1,
            area: 'Гомельская',
            region: 'Гомель',
            picture: 'https://miro.medium.com/max/3016/0*5RLp-IJkJC6dLHvV.jpg',
            description: 'Мой двор',
            lat: 53.9006011,
            lng: 27.558972
        },
        {
            _id: 2,
            area: 'Гродненская',
            region: 'Гродно',
            picture: 'https://www.it-courses.by/wp-content/uploads/2017/03/road-sign-361513_960_720.jpg',
            description: 'Наши активисты Наши активисты Наши активисты Наши активисты',
            lat: 53.9006011,
            lng: 27.558972
        },
    ]

    getAsyncFetch = (urladdon, method='GET', obj=null) => {

        const request = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }
        if (obj) request.body = JSON.stringify(obj);

        return fetch(SERVER_URL + urladdon, request)
            .then(res => {
                return ([200,201].includes(res.status)) ? res.json() : Promise.reject(CONNECTION_ERROR_MESSAGE)
            })
            .then(res => {
                if(res === undefined) {
                    return Promise.reject(CONNECTION_ERROR_MESSAGE);
                }
                return res.result ? Promise.resolve(res.result) : Promise.resolve(res.message);
            })
            .catch(error => {
                return Promise.reject(CONNECTION_ERROR_MESSAGE);
            })
    }

    getData = async (time) => {
      return this.getAsyncFetch("/api/activity?time=" + time);
    };

    getItem = async (id) => {
        return this.getAsyncFetch("/api/activity/" + id);
    };
}
