import React from "react";

const PagesController = props => {
    const { labels, page, setPage, children, contentMarginBottom, contentMarginTop } = props

    if (!children || children === 0) return null;
    if (!labels || labels.length === 0) return null;

    return (
        <div className='hp-100'>
            <div className='d-flex-wrap-sb-ac'>
                <div className="d-flex-wrap">
                    {
                        labels.map((item, index) => {
                            const style = index === page ? "button-link-selected" : "button-link-regular";
                            return <button onClick={() => setPage(index)} key={index} className={style + " mr-5 mbweb-10 fs-14-12"}>{item}</button>
                        })
                    }
                </div>
            </div>
            <div className="separator" style={{marginTop: contentMarginTop || 10, marginBottom: contentMarginBottom || 0 }} />
            {props.children[page]}
        </div>

    )
}
export default PagesController
