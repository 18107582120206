import React, {useState} from "react";
import Linkify from 'react-linkify';
import {useEffect, useCallback, useRef} from 'react'
import ReactPlayer from 'react-player'
import {FiX, FiChevronRight, FiChevronLeft} from 'react-icons/fi'
import useWindowDimensions from "../../hooks/useWindowDimensions";

const PopupPlayer = props => {

    const {item, mediaList, callbackClose } = props
    const { width } = useWindowDimensions();

    const [currentMedia, setCurrentMedia] = useState(item)

    const divRef = useRef();

    const escFunction = useCallback((event) => {
        event.preventDefault();
        if(event.keyCode === 27) {
            callbackClose()
        }
        if(event.keyCode === 37) {
            nextMedia(-1)
        }
        if(event.keyCode === 39) {
            nextMedia(1)
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        setCurrentMedia(item)

        divRef.current.focus();
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
        // eslint-disable-next-line
    }, [item]);

    const nextMedia = (shift) => {
        let index = mediaList.findIndex(media => media._id === currentMedia._id);

        if (index > -1) {
            index += shift;
            if (index >= mediaList.length) index = 0;
            if (index < 0 ) index = mediaList.length - 1;
            setCurrentMedia(mediaList[index]);
        }
    }

    const isMobile = width < 450;

    return (
        <div className='popup'>
            <div className="popup_inner">
                {(currentMedia.type === 'video' || currentMedia.type === 'youtube') &&
                    <ReactPlayer width="100%"
                                 height="100vh"
                                 controls={true}
                                 playing={true}
                                 url={currentMedia.url}/>
                }
                {(currentMedia.type === 'photo' || currentMedia.type === 'image') &&
                    <div>
                        <img style={{width: '100%', height:'100vh', objectFit: 'contain'}} src={currentMedia.url} alt='player' />
                    </div>
                }
                {(currentMedia.type === 'text' || currentMedia.type === 'link') &&
                <div>
                    <div className='white d-flex-center' style={{height: '100vh', overflowY: 'scroll'}}>
                        <Linkify>
                            <p className='pre-line' style={isMobile ? {width: '90%', paddingTop: 130, paddingBottom: 20} : {width: 800}}
                               dangerouslySetInnerHTML={{ __html: currentMedia.description + `${currentMedia.url ? ': ' + currentMedia.url : ''}` }}>
                            </p>
                        </Linkify>
                    </div>
                </div>
                }
                <div className='popup_inner_close'>
                    <div className='d-flex-ac'>
                        {isMobile && <button className='button-round mr-10' onClick={() => nextMedia(-1)} ref={divRef}><FiChevronLeft size={24}/></button>}
                        {isMobile && <button className='button-round mr-10' onClick={() => nextMedia(1)} ref={divRef}><FiChevronRight size={24}/></button>}
                        <button className='button-round' onClick={callbackClose} ref={divRef}><FiX size={24} /></button>
                    </div>
                </div>
                {mediaList && mediaList.length > 1 && !isMobile &&
                    <div>
                        <div className='popup_inner_left'>
                            <button className='button-round' onClick={() => nextMedia(-1)} ref={divRef}><FiChevronLeft
                                size={24}/></button>
                        </div>
                        <div className='popup_inner_right'>
                            <button className='button-round' onClick={() => nextMedia(1)} ref={divRef}><FiChevronRight
                                size={24}/></button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default PopupPlayer;
