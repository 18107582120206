import APIService from "../services/api-service";
import { MONTH } from "../constants/durations";

const dataRequested = () => {
    return { type: 'FETCH_DATA_REQUEST' }
};

const dataLoaded = (data) => {
    return {
        type: 'FETCH_DATA_SUCCESS',
        payload: data
    };
};

const itemLoaded = (data) => {
    return {
        type: 'FETCH_ITEM_SUCCESS',
        payload: data
    };
};

const dataError = (error) => {
    return {
        type: 'FETCH_DATA_FAILURE',
        payload: error
    };
};

export const fetchData = (dispatch) => (duration= MONTH) => {
    const userService = new APIService();
    dispatch(dataRequested());
    userService.getData(duration)
        .then((data) => dispatch(dataLoaded(data)))
        .catch((err) => dispatch(dataError(err)));
};

export const fetchItem = (dispatch) => (id) => {
    const userService = new APIService();
    dispatch(dataRequested());
    userService.getItem(id)
        .then((data) => dispatch(itemLoaded(data)))
        .catch((err) => dispatch(dataError(err)));
};

export const errorReset = (dispatch) => () => {
    dispatch(dataError(null));
};
