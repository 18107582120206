import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { errorReset } from '../../actions/index'

const ERROR_DELAY = 5000;

const ErrorPanel = props => {

    const { error, errorReset } = props
    const [visible, setVisible] = useState(false)

    useEffect(() => {

        if (!error) return;

        setVisible(true)
        const interval = setTimeout(() => {
            setVisible(false);
            errorReset();
        }, ERROR_DELAY);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    if (!visible) return null;

    console.log(error)

    return (

        <div className="errorPanel">
            <p>{error}</p>
        </div>

    );

}

const mapStateToProps = ({ data: { error }}) => {
    return { error };
};

const mapDispatchToProps = (dispatch) => {
    return {
        errorReset: errorReset(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPanel);
