import React, {useEffect} from "react";
import { connect } from "react-redux";
import { fetchItem } from "../../actions";
import PopupPlayer from "../popup-player";

const MediaView = props => {

    const {media, fetchItem} = props

    useEffect(() => {
        if (!media._id) {
            const {id} = props.match.params;
            fetchItem(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!media) return null;

    const closePreview = () => {
        props.history.push('/')
    }

    return (
        <div>
            <PopupPlayer item={media} callbackClose={closePreview} />
        </div>
    );

}


const mapStateToProps = ({ data: { media }}) => {
    return { media };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchItem: fetchItem(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaView);

