import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import App from "./components/app";
import ErrorBoundry from "./components/error-boundry";
import {Provider} from 'react-redux';
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import store from './store';

import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://b045358228294aca87f851e008da88bb@o756872.ingest.sentry.io/6261533",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundry>
            <Router>
                <ScrollToTop/>
                <App/>
            </Router>
        </ErrorBoundry>
    </Provider>,
    document.getElementById('root')
);
